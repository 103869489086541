const routes = {
	home: "/",
	contact: "mailto:sales@impilo.health",
	terms: "/terms",
	privacy: "/privacy",
	requestDemo: "/request-demo",
	blog: "/blog",
	about: "/about",
	solutions: "/solutions",
	integrations: "/solutions",
	docs: "https://docs.impiloplatform.com",
}

const socials = {
	linkedin: "https://www.linkedin.com/company/impilo-inc/",
	careers: "https://impilo-inc.breezy.hr/",
	phone: "tel:+12028385839",
}

const links = {
	...routes,
	...socials,
}

export default links
