import { useLocation } from "@reach/router"
import LinkButton from "components/Buttons/Link"
import Primary from "components/Buttons/Primary"
import { ReactComponent as LogoSVG } from "images/global/Logo.svg"
import UniversalLink from "library/Loader/UniversalLink"
import { fresponsive } from "library/fullyResponsive"
import { pathnameMatches } from "library/functions"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import { desktopBreakpoint } from "styles/media"
import links from "utils/links"

export default function Header() {
	const pathname = useLocation().pathname

	const hideDemo = pathnameMatches(pathname, links.requestDemo)

	return (
		<Wrapper>
			<Inner>
				<UniversalLink to={links.home}>
					<Logo />
				</UniversalLink>
				<Links>
					<LinkButton to={links.solutions}>Our Solutions</LinkButton>
					<LinkButton to={links.about}>About Us</LinkButton>
					<LinkButton to={links.blog}>Blog</LinkButton>
					<LinkButton to={links.docs}>Docs</LinkButton>
					{!hideDemo && <Primary to={links.requestDemo}>Request Demo</Primary>}
				</Links>
			</Inner>
		</Wrapper>
	)
}

const Wrapper = styled.header`
  display: grid;
  place-items: center;
  height: auto;
  background: ${colors.blue05};
`

const Inner = styled.div`
  width: 100%;
  max-width: ${desktopBreakpoint}px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${fresponsive(css`
    padding: 50px 50px 0;
  `)}
`

const Logo = styled(LogoSVG)`
  height: auto;
  cursor: pointer;

  ${fresponsive(css`
    width: 69.3px;
    overflow: visible;
  `)}
`

const Links = styled.div`
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 40px;
  `)}
`
